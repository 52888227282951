@import "@angular/material/prebuilt-themes/indigo-pink.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: white;
  text-align: center;
}

.div {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  margin: 0;
}

button {
  background-color: #78866B;
  border: 0;
  font-size: 20px;
  font-family: inherit;
  margin-top: 10px;
  opacity: 0.7;
  transition: opacity 0.3s;
  font-weight: 400;
}

button:hover {
  opacity: 1;
  text-decoration: underline;
  transition: opacity 0.3s;
  cursor: pointer;
  outline-color: #4c5940;
  outline-width: thick;
}

button:focus,
label:focus {
  outline-color: #4c5940;
  outline-width: thick;
}

p {
  margin: 0;
  padding-bottom: 2px;
  text-align: left;
  padding-left: 5px;
}

.input-class {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.unselectable {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
